import * as React from 'react';
import { initializeApp } from 'firebase/app';
import { applyActionCode } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import * as fbAuth from 'firebase/auth';
import { useEffect, useState } from 'react';
import logo from '../../images/logo.svg';
import * as css from './auth.module.css';
import { Paper } from '@mui/material';
import { isBrowser } from '../../helpers';

const firebaseConfig = {
  apiKey: 'AIzaSyAC1ku4BEamHNxUrL8oWkeq-hSATLgxnfE',
  authDomain: 'copingcard.firebaseapp.com',
  projectId: 'copingcard',
  storageBucket: 'copingcard.appspot.com',
  messagingSenderId: '783662036013',
  appId: '1:783662036013:web:093d8dd01ca1e47912b4a4',
  measurementId: 'G-ZXTDZMXEC6',
};

export const firebaseApp = initializeApp(firebaseConfig);

const DeepAuth = () => {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');

  const search = isBrowser && window?.location?.search.substring(1);
  const parsedSearch: Record<string, any> = search
    ? JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
      )
    : {};

  if (
    isBrowser &&
    parsedSearch?.lang?.startsWith('ru') &&
    !window?.location.pathname.includes('/ru')
  ) {
    const replaceUrl = isBrowser
      ? window?.location.protocol +
        '//' +
        window?.location.host +
        '/ru' +
        window?.location.pathname +
        window?.location.search
      : '';

    isBrowser && window.location.replace(replaceUrl);
  }

  const applyCode = async () => {
    const auth = fbAuth.getAuth(firebaseApp);
    try {
      await applyActionCode(auth, parsedSearch.oobCode as string);
      setIsSuccess(true);
    } catch (error) {
      setErrorText(JSON.stringify(error));
    }
  };

  useEffect(() => {
    const handle = setTimeout(() => {
      if (
        ['resetPassword', 'verifyEmail'].includes(parsedSearch?.mode) &&
        parsedSearch?.oobCode
      ) {
        applyCode();
      }
    }, 500);

    return () => {
      clearTimeout(handle);
    };
  }, []);

  return (
    <div>
      <Paper key={`key-auth`} className={css.containerHeader} elevation={5}>
        <div className={css.logo}>
          <img src={logo} alt="CopingCard Logo" width="200px" />
        </div>
      </Paper>
      <div className={css.content}>
        <h2>{t('emailVerification')}</h2>
        {isSuccess ? (
          <div>
            <div>{t('emailVerificationSuccess')}</div>
            <div className={css.paragraph}>{t('sucessNextSteps')}</div>
          </div>
        ) : null}
        {errorText ? (
          <div>
            <div>{t('emailVerificationError')}</div>
            <div className={css.paragraph}>
              {t('errorDetails')}: {errorText}
            </div>
            <div className={css.paragraph}>
              {t('errorNextSteps')}:{' '}
              <a href="mailto:support@copingcard.com">support@copingcard.com</a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DeepAuth;
